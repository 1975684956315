const env = process.env.REACT_APP_ENV
const redirectAfterLogin = encodeURIComponent(window.location.href)
const envConfig = {
  dev: {
    LOGIN_URL: `https://test-lmit-finditems-nprod-ping-dhounkje6q-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'http://localhost:8080',
    SCANDIT_LICENSE_KEY:
      'AWIwiD54HUXvQf/Iwxtn6jQ3xOkdG0g9WQnsEYpaVdsPFbqsYU5QF1RL09CMTMcPpGNdWXdhWYGVMxlMmBGyZFttoJZ2IQQPWUewybd9VLr0XHraxk+qNvVxMDLCdSSmRxZXiJsty5JyJuKGUSjxaJaMLEfdmUyLiDyB8vtxDQRFuWJx/+sLfeF8I2wB/C4irroGsFJ4umM9v0PJ3u4irKqeKEcvhOzoGBaBlzvD7YkjwQj+s4fP3Akgt76wg2vB3YKlKdPMkAznyJHE1uBO7bi9L5OlaNlPjpkoP2iGTSVBmr1+Jo+vT9V4qVEZbVGIt8W7vAtWpKuSgRWneMJ48xKF8H22njephWmi29HNI7sijgo6sjrU++GN2SUVzK0YofyIQlkWZIep65f+NIxqh+n+PS0WDhQS/SrO1nVf7rFuGdKkInrGZQ36ph4jnED3mliR+zJG5yUWHMRKeczPamXaKB3NgYyIH3pyVFEi4h0iie0qAzTdhcaETROWOduhkKPCmQbsNnzUc0kZEvqr7T5uzbLKByEq3DHLKWlX/EhB5RkxGZXii9PjdwJaEiXRezlaVtmAh2je5B6CF02Xd+sAenpyQu0nfGk7HDxy7Nz/zSdb+ChbG0XFpa+uTz4nGoMyFProjIQzsdESB8vA1EQ/xbW572KO0Q1MR4dovWFs5+tI9c6abzcerYwi78/HBg30K6IVGfAXNBWYQGlHbOdKX3DxGG3HxHzC24vVl62+HBotqQ8eampF0O//qZVcdBUP8mFOFSAH4hYVPa4mT6Xmq8a3FKsIh1AKSKwdFT6TTAgWZDoyGo09OKtV0cE=', //ngrok
  },
  test: {
    LOGIN_URL: `https://test-lmit-finditems-nprod-ping-dhounkje6q-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://test-finditems-api-dhounkje6q-ew.a.run.app',
    SCANDIT_LICENSE_KEY:
      'AVSQSzlfGqeBBqFaHSV5MuQYVIweFEsb529CJmEgHrwoI41PvSgcOBV+UoFjSRHDVXPKeUR9zJ2NTEkYTmjtZfdiCb5VclQ6IF0gUE5kTsnXYfF6iEYI4iVrmgiZS1hgb1Vkmto+t6Ztbf3qATsR2IpA2mtbO3iLnx2MWz2jab9AI6z8kBU/ymHTXYUs4wC/Z6mTDBPcin6xXoYw7E7LZ6eROOUUdeb7TW78DEsPxQ3la6sz7b0q+MWmRtk2M3WO0ElzsX+GM74ygUgtBMcIOvtO4XEsA40+/njOXA0/Txe78GVOxhzQhEk/e9qiFpfzX80gI+PEbHlOnGBZidXHM5jS/FRy4wXYVLLGERJ41ETJlpU1zYIcbMnQSwfDv/Q2iEaRSZHnm3gGWCP9M+HwwwNeodnWDSOF7v8K7tgdi67etuTAEBvty1IH5zICnfgk8NlrYzYjT0aIALLMJlh1TQxBU6znRNP9oPHnfO4kunIkWdy8/pyBT/uvd8K9SqiXaipK5oVuqSDkAv5PHUdfKAHFWw8Rhr0dC0Pzz35nsGB38x0a3p1STMBUS26E4B7V0WMj0SeAACdlW+v4o+YNExOjBUEumrea3CuKVAaZtXKV5DacWYgM5uWRG1xz0gIhQslFHw0JzYOZzaY2CVhcYf0VgA0MqgGsSZA6hk8TO4FB8mbEYlGqDeQSxYFtU+/X29u2p9EkpzwQEUChUJezQO1iGV36XSeJbi0rzEJpv4T0gn7hWseSTTQK3+K5fhdMXVwd+kXs2MvMxF0Q4ZNVURzc/ye0+BaxC25Z2WhQZMn5XB8xvvCMYrzHLMwYuB8P6uvEG8JnfQL+7g==',
  },
  prod: {
    LOGIN_URL: `https://prod-lmit-finditems-prd-ping-w2e3et7fzq-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://prod-finditems-api-w2e3et7fzq-ew.a.run.app',
    SCANDIT_LICENSE_KEY:
      'AeSQ+NWBQbc7LYs3jSnpyUEPsd9+E5udHXR4pD58IVSFAXD3YmlzHula/iAOdZ393Hv/KbV5kwsLSxVCGmCHgx1tvHIeXbZUzzEQrXZ/bawPWZTfgjhQ5rRDv45XCoKe6T0w6YVAhT8MQJx0chEyGtoXMygoyjhBZ+FXVKXCSWjsJUbrVbLfBpxXMoy9ILtMZXKWtzlS3ZRR+0//4oOQJKx2G61fz4K4OAFBW4vSsXOpSaAOuR214mgO55lZ5TGirig8ccj/pWmkBAfYjxrDqRdq2L4ocG+Su9l/UeA99L1UXzh/KWhTamGcUjabctFU92Uon7YCERQg9zBA/cMH3EOgMElhEqd6zRcd86PqtXTWt0R/NORVV5ElGjQpQTeN4U1PmwCHQdgJ4Kvq4DRt6A9UVe8qFuujJPjFCxXv7WgzJE6+5/iEzRwgmhD8sXfA7AzVPa+dSeCRPDktJCkMFXEC7YJGv3xSMSrLqWFEz4eWhRwKF9v8ZRWk8DBnKm0EpDMCUze1SdQVxp/uVxBilDVMP1SHNbO2v1Y3h6N+cAtJSvZ80s7gE0P3zQJzh/ePhWHN4vXA7o99huhLNK3trejj/RNyWNuHirkjJhZOjpJ3K8WaCiwpT74THSkzc3NTb2mnjPBYH6HXcFVdqMPqgx+B814sdZy+ApbcQmH6DwcdDvYh02E23wANEn1rG2U68hE9eFJPG1UQgehtQ9I3hhqeAui/nLWfPH2ivnw5CznZGrylZofAWLBFq+ge4KjFBMNq4L5vEZA/bDxiNca8QzG+iS7+2ZZmJvOP0e+8q2gJKQ67gMoQHqVQDQnXaLEMDw==',
  },
}

export default envConfig[env]
